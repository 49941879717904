import React, { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import cx from 'classnames'
import { Button, composeValidators, fieldValidations } from '../dependencies'
import { Field } from 'react-final-form'
import Image from 'next/image'

const AccountList = ({
   publications
}) => {
    return (
        <Field name='publisher_id' required={true} validate={composeValidators(fieldValidations.required)}>
            {({input, meta}) => (
                <>
                    <RadioGroup value={input.value} onChange={input.onChange}>
                        <div className="rounded-md border border-gray-300 bg-white">
                            <ul role="list" className='-space-y-px rounded-md bg-white'>
                                {publications.map((publication, index) => (
                                    <RadioGroup.Option
                                        key={index}
                                        value={publication?.id}
                                        className={({ active, checked }) =>
                                            cx(
                                                active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                                                checked ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200',
                                                'relative flex cursor-pointer border p-4 hover:bg-gray-50 focus:outline-none'
                                            )
                                        }
                                    >
                                        {({ checked, active }) => (
                                            <li className='relative flex justify-between'>
                                                <div className='flex min-w-0 gap-x-4'>
                                                    <Image 
                                                        src={publication?.icon}
                                                        className='h-12 w-12 flex-none rounded-full bg-gray-50'
                                                        height={48}
                                                        width={48}
                                                        alt={publication?.name}
                                                    />
                                                    <div className='min-w-0'>
                                                        <RadioGroup.Label as='p' className='text-sm font-medium leading-6 text-gray-900'>@{publication.username}</RadioGroup.Label>
                                                        <RadioGroup.Description as="span" className="inline text-sm text-gray-700">
                                                            {publication.name}
                                                        </RadioGroup.Description>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                    </RadioGroup.Option>
                                ))}
                                <RadioGroup.Option
                                    value='create_new'
                                    key={publications.length + 1}
                                    className={({ active, checked }) =>
                                        cx(
                                            active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                                            checked ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200',
                                            'relative flex cursor-pointer border p-4 hover:bg-gray-50 focus:outline-none'
                                        )
                                    }
                                >
                                    <li className='relative'>
                                        <div className='flex min-w-0 gap-x-4 items-center justify-center'>
                                            <div className='h-12 w-12 flex-none rounded-full bg-gray-10 border-2 border-gray-600'>
                                            </div>
                                            <div className='min-w-0'>
                                                <RadioGroup.Label as='p' className='text-sm font-medium leading-6 text-gray-900 '>Create a new account</RadioGroup.Label>
                                            </div>
                                        </div>
                                    </li>

                                </RadioGroup.Option>
                            </ul>
                        </div>
                    </RadioGroup>
                    {meta.touched && meta.error && (
                        <p className='text-sm text-gray-500 mt-2'>{meta.error}</p>
                    )}
                </>
            )}
        </Field>
    )
}
export default AccountList