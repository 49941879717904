import { showConsoleLogs } from '@/utils/console'

const PrefixedTextInput = ({
    feedback,
    className,
    input,
    placeholder,
    meta,
    autoFocus,
    min,
    handleChange,
}) => {
    if (showConsoleLogs()) {
        console.log('feedback', feedback)
        console.log('meta', meta, meta.error, meta.touched)
    }
    return (
        <div className="relative flex flex-col">
            <div className="flex h-12 w-full flex-row-reverse rounded-lg">
                <input
                    className={`${
                        meta.error && meta.touched && 'input-error'
                    } userhandle input peer w-full transition-colors duration-300 focus:border-violet-500 focus:outline-none ${className}}`}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    autoCorrect="off"
                    autoCapitalize="none"
                    spellCheck="false"
                    id={input.name}
                    min={min}
                    {...input}
                    onChange={(e) => {
                        input.onChange(e)
                        return handleChange(e)
                    }}
                />
                <label
                    className={`z-10 flex items-center text-black text-sm font-medium text-opacity-70 transition-colors duration-300 peer-focus:border-violet-500 peer-focus:text-violet-500`}
                    htmlFor="username"
                >
                    hype.co/@
                </label>
            </div>
            <span className="input-validation-text success">{feedback}</span>
            {meta.error && (
                <span className="input-validation-text error">{meta.error}</span>
            )}
        </div>
    )
}
export default PrefixedTextInput
