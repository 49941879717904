import { Field } from 'react-final-form'
import React from 'react'
import Form from '@piconetworks/pkg-form'
import { formatSocialLinks, parseURLFromUsername } from '@/utils/form/formatString'
import { showConsoleLogs } from '@/utils/console'

const SocialField = ({ name = 'hm', label, placeholder, onChange }) => {
    showConsoleLogs() && console.log('inside of social field', name, label, placeholder, onChange)
    return (
        <>
            <Field
                name={name}
                type='text'
                component={Form.Input}
                label={label}
                placeholder={placeholder}
                onChange={onChange}
                format={(value) =>
                    formatSocialLinks({
                        value
                    })
                }
                parse={(value) =>
                    parseURLFromUsername(
                        value,
                        name
                    )
                }
            />
        </>
    )
}

export default SocialField
