/* eslint-disable react/no-unescaped-entities */
import Image from 'next/image'
import { 
    useQueryParams, 
    Button, 
    tagImageSource,
} from './dependencies'
import { useTrial } from '@/entities/Purchases'
import { useProgress } from '@/entities/Progress'
import { useEffect } from 'react'

const SubscriptionWall = () => {
    const { 
        tagType, 
        tagColor,
        inApp,
        queryToKeep,
    } = useQueryParams()

    
    const {
        appTierName,
        trialLength,
        generateCheckoutSession,
        generateCheckoutSessionInProgress,
    } = useTrial()
    
    const imageSource = (tagType && tagColor) ? tagImageSource(tagType, tagColor) : null

    const { goToNextStep } = useProgress()

    useEffect(() => {
        if (inApp) {
            goToNextStep()
        }
    }, [inApp])

    return (
        <> 
            <div className='grow items-center content-center justify-between text-center w-full'>
                <div>
                    {imageSource ? (
                        <Image src={imageSource} alt={`${tagType} ${tagColor}`} width={250} height={250} priority className='mt-12 mb-8 mx-auto' />
                    ) : (
                        <Image 
                            alt="Hype Brandmark"
                            className="rounded-[32px] mt-12 mb-8 mx-auto"
                            src="/kittens/hype_brandmark_white.svg"
                            width={150}
                            height={150}
                            priority 
                        />
                    )}
                    <div className='mt-8 mb-8'>
                        <h1 className='title' data-testid='title-linked-tag'>Get {trialLength} free of {appTierName} plan</h1>
                        <p className='subtitle !mb-0' data-testid='subtitle-linked-tag'>Activate today. Cancel anytime.</p>
                    </div>
                </div>
            </div>
            <div className='flex flex-col w-full items-stretch'>
                <Button
                    type='button'
                    // href={`${endpoints.PICO_API_URL()}/nfc/checkout/start?edition_id=4a28db36-69a0-4b4f-b68e-698908a81e20&publisher_id=${publisherId}`}
                    onClick={() => generateCheckoutSession(queryToKeep)}
                    className='mt-8'
                    variant='primary-black'
                    fullWidth
                    disabled={generateCheckoutSessionInProgress}
                    loading={generateCheckoutSessionInProgress}
                    data-testid='in-app-button-signup'
                >
                    Activate free access
                </Button>
                <Button type='button' className='mt-2 mb-6' onClick={() => goToNextStep()} size='md' variant='secondary' color='secondary-gray' data-testid='do-it-later' fullWidth>
                    I'll do it later
                </Button>
            </div>
        </>
    )
}

export default SubscriptionWall
