import { useCallback } from "react"
import { useFormState, useForm } from "react-final-form"
import cx from 'classnames'
import SocialField from "./components/SocialField"
import { useInstagram, useTiktok, useSocials, formatSocialLinks, Button } from './dependencies'
import { usePublisher } from '@/entities/Publisher'

const SocialScraping = () => {
    const { requestInstagram } = useInstagram()
    const { requestSocials } = useSocials()
    const { requestTiktok } = useTiktok()

    const { publisherId } = usePublisher()

    const { submit } = useForm()

    const { values: { instagram, tiktok }, submitting, valid, submitSucceeded } = useFormState()

    const handleSocialScraping = useCallback((e) => {
        e.preventDefault()

        // begin scraping
        if (instagram) {
            requestInstagram({
                instagram: formatSocialLinks({ value: instagram, removeSymbol: true }),
                publisherId
            })
        }

        if (tiktok) {
            requestTiktok({
                tiktok: formatSocialLinks({ value: tiktok }),
                publisherId
            })
        }

        // submits, save username and move to the next step
        requestSocials({
            publisherId,
            tiktok,
            instagram
        })
        submit()

    }, [instagram, tiktok, publisherId, requestInstagram, requestTiktok, requestSocials, submit])

    // not using submitSucceeded for processing because it's not becoming false after the previous step for some reason
    const processing = submitting
    return (
        <>
            <div className='max-w-[375px]'>
                <SocialField name='instagram' label='Instagram' placeholder={'@your Instagram handle'} />
                <SocialField name='tiktok' label='TikTok' placeholder={'@your TikTok handle'} />
                <Button 
                    className={cx('mt-8 md:mt-16 block w-full', 'plausible-event-name=Signup+Step+2')}
                    fullWidth
                    color='primary'
                    disabled={submitting}
                    loading={submitting} 
                    type='button' 
                    onClick={(e) => handleSocialScraping(e)}
                >
                    {processing ? 'Processing...' : 'Continue'}
                </Button>
            </div>
        </>
    )
}
export default SocialScraping
