import { composeValidators, fieldValidations } from '@/utils/form/validators'
import isEmpty from 'lodash/isEmpty'
import createAsyncSaga from '@/utils/store/createAsyncSaga'
import { usePublisher, useUsername, getUsernameTemporary } from '@/entities/Publisher'
import { useProcessing } from '@/entities/Progress'
import Button from '@/components/Button'

const name = 'usernameCheck'

export {
    Button,
    composeValidators,
    fieldValidations,
    isEmpty,
    name,
    createAsyncSaga,
    usePublisher,
    useUsername,
    getUsernameTemporary,
    useProcessing
}
