import { useInstagram, useTiktok } from "@/entities/Scrapeyard"
import { useSocials } from "@/entities/Publisher"
import { formatSocialLinks } from '@/utils/form/formatString'
import Button from '@/components/Button'

export {
    formatSocialLinks,
    useInstagram,
    useTiktok,
    useSocials,
    Button
}