import React, { useCallback, useEffect, useState } from 'react'
import { useFormState, useForm } from 'react-final-form'
import cx from 'classnames'
import { Button, usePublisher } from './dependencies'
import AccountList from './components/AccountList'
import { showConsoleLogs } from '@/utils/console'

const AccountPicker = ({}) => {
    const { values, submitting, valid } = useFormState()
    const { change, submit } = useForm()
    const { publications, requestToSwitchToPublisher, requestToSetScopeAndCallSetup, publicationsHaveBeenFetched, isNewUser, hasUserButNoPublisherUser } = usePublisher()

    const handleSubmit = useCallback((e) => {
        e.preventDefault()
        if (values.publisher_id && values.publisher_id !== 'create_new') {
            // redirect them to the last step
            requestToSwitchToPublisher({
                publisher_id: values.publisher_id
            })
            // change('goToStep', stepCount - 1)
            // submit()
        } else if (values.publisher_id === 'create_new') {
            requestToSetScopeAndCallSetup()
            // submit()
        }
    }, [values, requestToSwitchToPublisher, requestToSetScopeAndCallSetup])
    
    useEffect(() => {
        showConsoleLogs() && console.log('AccountPicker publications.length', publications.length, isNewUser, values)
        // todo fix for has user but no publisheru ser
        if (hasUserButNoPublisherUser === true || publications.length === 0) {
        // if (publications.length === 0) {
            change('publisher_id', 'create_new')
            handleSubmit({ preventDefault: () => {} })
        }
    }, [publications, handleSubmit, publicationsHaveBeenFetched, isNewUser, change, values, hasUserButNoPublisherUser])

    return (
        <div className="max-w-[375px]">
            <AccountList publications={publications} />
            <Button
                className={cx('mt-6 md:mt-12 block', 'plausible-event-name=AccountPicker')}
                color='primary'
                fullWidth
                disabled={!valid || submitting}
                loading={submitting}
                type='submit'
                onClick={(e) => handleSubmit(e)}
            >
                {submitting ? 'Processing...' : 'Continue'}
            </Button>
        </div>
    )
}

export default AccountPicker
