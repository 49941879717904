/** @namespace FeatureManager.useFeatureManager */

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    getFeatures,
    getAppsTiers,
    fetchApps,
    getFetchAppsInProgress,
    getApps,
} from '../slice'

import { getBillingInfoStatuses, getPublisherId } from '../dependencies'

const useFeatureManager = () => {
    const publisherId = useSelector(getPublisherId)
    const dispatch = useDispatch()
    const features = useSelector(getFeatures)
    const apps = useSelector(getApps)
    const appTiers = useSelector(getAppsTiers)

    const billingStatuses: BillingStatus[] = useSelector(getBillingInfoStatuses)
    const isAppsLoading = useSelector(getFetchAppsInProgress)

    const [decoratedApps, setDecoratedApps] = useState([])

    useEffect(() => {
        if (isAppsLoading === true) {
            return
        }

        if (apps?.length > 0) {
            return
        }

        if (!publisherId) {
            return
        }

        dispatch(
            fetchApps({
                publisherId
            })
        )
    }, [dispatch, publisherId, apps, isAppsLoading])

    const getAppByLabel = useCallback(
        (appLabel) => {
            return apps?.find(({ label }) => label === appLabel)
        }
    , [apps])

    const getAppTierByLabel = useCallback(
        (appLabel) => {
            const targetApp = appTiers?.find(({ label }) => {
                return label === appLabel
            })

            if (!targetApp) {
                return null
            }

            const _getFeatureByLabel = (labelToFind) => {
                const feature = targetApp?.features?.find(
                    ({ label = null } = {}) => label === labelToFind
                )

                const featureData =
                    features.find(({ id = null } = {}) => id === feature?.id) ||
                    {}

                // this is wrong, this should be using the spread like this:
                /*
             {
                ...featureData,
                ...feature
             }
             */

                return Object.assign(featureData, feature)
            }

            const getPricingById = (productPricingId) => {
                return targetApp?.pricings?.find(
                    (pricing = null) => pricing?.id === productPricingId
                )
            }

            const getBillingData = (stripeProductId) => {
                const billingData: BillingStatus = billingStatuses.find(
                    (status) => {
                        const { subscription } = status
                        return subscription?.plan?.product === stripeProductId
                    }
                )
                // append only what we need
                const filteredBillingData = {
                    status: billingData?.status,
                    amount: billingData?.subscription?.plan?.amount,
                    currency: billingData?.subscription?.currency,
                    currentPeriodEnd: billingData?.subscription?.current_period_end,
                    trialEnd: billingData?.subscription?.trial_end,
                }

                return filteredBillingData
            }

            const billingData = getBillingData(targetApp?.stripe_product_id)

            const creditTypes = Object.keys(
                targetApp.credits.reduce((acc, credit) => {
                    acc[credit?.type] = null

                    return acc
                }, {})
            )

            return {
                ...targetApp,
                getFeatureByLabel: _getFeatureByLabel,
                getPricingById,
                publisherId,
                billingData,
                creditTypes,
            }
        },
        [
            appTiers,
            features,
            publisherId,
            billingStatuses,
        ]
    )

    const getFeatureByLabel = useCallback(
        (featureLabel) =>
            features.find(({ label }) => label === featureLabel) || {},
        [features]
    )

    const hasFeature = useCallback((featureLabel) => {
        return getFeatureByLabel(featureLabel)?.['hasAccess']
    }, [getFeatureByLabel])

    const getActiveApps = useCallback(() => {
        const filteredApps = (decoratedApps || []).filter(
            (app) => app?.billingData?.status === 'active'
        )
        return filteredApps
    }, [decoratedApps])

    const getInactiveApps = useCallback(() => {
        const filteredApps = (decoratedApps || []).filter(
            (app) => app?.billingData?.status === 'inactive'
        )
        return filteredApps
    }, [decoratedApps])

    useEffect(() => {
        const _apps = appTiers?.map((app) => {
            return getAppTierByLabel(app?.label)
        })

        setDecoratedApps(_apps)
    }, [appTiers, getAppTierByLabel])

    const activeApps = getActiveApps()
    const inactiveApps = getInactiveApps()

    const getAppTierLabelByPricingId = useCallback((pricingId = null) => {
        return appTiers.find((appTier) => {
            return appTier?.pricings?.find((pricing) => {
                return pricing?.id === pricingId
            })
        })?.label
    }, [appTiers])

    const getAppTierByPricingId = useCallback((pricingId = null) => {
        const appTierLabel = getAppTierLabelByPricingId(pricingId)

        return getAppTierByLabel(appTierLabel)
    }, [getAppTierLabelByPricingId, getAppTierByLabel])

    return {
        features,
        apps: decoratedApps,
        activeApps,
        inactiveApps,
        isAppsLoading,
        getAppByLabel,
        getFeatureByLabel,
        hasFeature,
        getAppTierByLabel,
        getAppTierByPricingId,
    }
}

export default useFeatureManager
