import React, { useCallback, useEffect, useState } from 'react'
import { Field, useFormState, useForm, useField } from 'react-final-form'
import cx from 'classnames'
import { Button, composeValidators, fieldValidations, useProcessing, usePublisher, useUsername } from './dependencies'
import PrefixedTextInput from './components/PrefixedTextInput'
import { showConsoleLogs } from '@/utils/console'

const UsernameCheck = ({}) => {
    const { publisherId } = usePublisher()
    const { mutators } = useForm()
    const {
        valid,
        submitting,
        values: { username },
    } = useFormState()

    const {
        requestToCheckUsername,
        usernameIsAvailable,
        usernameChecked,
        usernameTemporary,
        requestToUpdateUsername,
        usernameEvaluated,
    } = useUsername()

    const {
        usernameCheckInProgress,
        publisherUpdateInProgress
    } = useProcessing()

    // not using submitSucceeded for processing because it's not becoming false after the previous step for some reason
    const processing = submitting || usernameCheckInProgress || publisherUpdateInProgress
    const [ usernameAvailable, setUsernameAvailable ] = useState(null)

    const { input: usernameField } = useField('username')

    if (showConsoleLogs()) {
        console.log('usernameChecked', usernameChecked)
        console.log('usernameIsAvailable', usernameIsAvailable)
        console.log('usernameTemporary', usernameTemporary)
        console.log('usernameEvaluated', usernameEvaluated)
        console.log('usernameCheckInProgress', usernameCheckInProgress)
        console.log('usernameAvailable', usernameAvailable)
        console.log('username', username)
    }

    useEffect(() => {
        showConsoleLogs() && console.log('checkUsernameSlowly?', usernameTemporary, !username, username)
        // we want this to run only when the username check hasn't run yet
        if (usernameAvailable === null && usernameTemporary && usernameEvaluated !== usernameField.value) {
            showConsoleLogs() && console.log('checking username slowly...', usernameTemporary)
            mutators.changeUsernameValue(usernameTemporary)
            requestToCheckUsername({ username: usernameTemporary, publisherId })
        }
    }, [mutators, publisherId, requestToCheckUsername, username, usernameAvailable, usernameEvaluated, usernameField.value, usernameTemporary])

    useEffect(() => {
        if (usernameChecked && usernameEvaluated === username && usernameIsAvailable) {
            mutators.unsetFieldError()
            setUsernameAvailable(true)
        } else if (usernameChecked && usernameEvaluated === username && !usernameIsAvailable) {
            mutators.setFieldError('username', 'Not available 😞')
            setUsernameAvailable(false)
        }
    }, [usernameChecked, usernameIsAvailable, username, usernameEvaluated, mutators])

    const checkUsernameSlowly = useCallback((username) => {
        requestToCheckUsername({ username, publisherId })
    }, [publisherId, requestToCheckUsername])

    const handleSubmit = useCallback(() => {
        if (usernameAvailable && usernameEvaluated === username) {
            requestToUpdateUsername({
                username,
                publisherId,
            })
        }
    }, [usernameAvailable, usernameEvaluated, username, requestToUpdateUsername, publisherId])

    const getFeedback = useCallback(() => {
        if (username == usernameEvaluated && !usernameCheckInProgress && usernameChecked && usernameAvailable) {
            return `Available! 🙌`
        }  else {
            return null
        }
    }, [usernameCheckInProgress, usernameEvaluated, usernameAvailable, username, usernameChecked])

    return (
        <div className="max-w-[375px]">
            <Field
                name="username"
                placeholder="username"
                type="text"
                feedback={getFeedback()}
                autoCorrect="off"
                autoCapitalize="none"
                validate={composeValidators(
                    fieldValidations.required,
                    fieldValidations.username
                )}
                component={PrefixedTextInput}
                autoFocus
                required
                handleChange={({ target }) => {
                    showConsoleLogs() && console.log('has it changed', usernameChecked, target.value)
                    if (usernameChecked !== target.value) {
                        return checkUsernameSlowly(target.value)
                    }
                }}
            />
            <Button
                className={cx('mt-8 md:mt-16 block', 'plausible-event-name=Signup+Step+3')}
                fullWidth
                color='primary'
                disabled={!valid || usernameEvaluated !== username || processing}
                loading={processing}
                type='submit'
                onClick={(e) => handleSubmit(e)}
            >
                {processing ? 'Processing...' : 'Continue'}

            </Button>
        </div>
    )
}

export default UsernameCheck
