import { useEffect, useState } from 'react'
import { useErrors } from '@/entities/Progress'
import { Wizard, Step } from '@/features/Wizard'
import Authenticate from '@/features/Authenticate'
import SocialScraping from '@/features/SocialScraping'
import UsernameCheck from '@/features/UsernameCheck'
import { useProgress } from '@/entities/Progress'
import AccountPicker from '@/features/AccountPicker'
import { useQueryParams } from '@/entities/QueryParams'
import { usePublisher } from '@/entities/Publisher'
import SubscriptionWall from '@/features/SubscriptionWall'
import DownloadWall from '@/features/DownloadWall'
import { useTrial } from '@/entities/Purchases'

const Signup = ({ isLoginDefault = false }) => {
    const {
        tagId,
        tagType,
        tagPackSize,
        login,
        inApp,
    } = useQueryParams()
    const {
        emailAuthError,
        emailSignupError,
        socialAuthError,
        setupPublisherError,
        publisherUpdateError,
        getMeError
    } = useErrors()

    const {
        hasUserButNoPublisherUser,
    } = usePublisher()

    const {
        showTrial
    } = useTrial()

    const [isLogin, setIsLogin] = useState(isLoginDefault)
    const { currentStep, setToContinueFlowAfterLogin } = useProgress('signup', inApp === true)

    useEffect(() => {
        if (currentStep === 1) {
            if (login || isLoginDefault) {
                setIsLogin(true)
                setToContinueFlowAfterLogin(false)
            } else {
                setToContinueFlowAfterLogin(true)
            }
        }
    }, [currentStep, setToContinueFlowAfterLogin, login, isLoginDefault])

    const tagDescription = tagPackSize > 1 ? 'stickers' : tagType === 'mini_sticker' ? 'sticker' : tagType
    const titleForStepOne = tagId ? `Activate your ${tagDescription}` : isLogin ? 'Log in to your account' : 'Create your account'
    // need to add a switch in case they click on continue with email
    const createYourAccountSubtitle = () => (
        <>
            By signing up, you are agreeing to the <a href='https://hype.co/terms/creator-agreement' target='_blank'>Hype Creator Agreement</a>.
        </>
    )
    return  (
        <Wizard>
            <Step
                title={titleForStepOne}
                subtitle={isLogin ? null : createYourAccountSubtitle()}
                hasSubmitError={emailAuthError || emailSignupError || socialAuthError || setupPublisherError}
                outerClasses='section'
                innerClasses='py-12 px-6'
                showHypeLogo={true}
            >
                <Authenticate isLogin={isLogin} setIsLogin={setIsLogin} showLoginToggle={true} />
            </Step>
            <Step
                title={'Log in or create an account'}
                subtitle={null}
                hasSubmitError={emailAuthError || emailSignupError || socialAuthError || setupPublisherError || getMeError}
                outerClasses='section'
                innerClasses='py-12 px-6'
                hide={hasUserButNoPublisherUser}
                showHypeLogo={true}
            >
                <AccountPicker />
            </Step>
            <Step
                title="Let's get started"
                subtitle='Add one or more of your social handles to automate building your page.'
                outerClasses='section'
                innerClasses='py-12 px-6'
                showHypeLogo={true}
               // hasSubmitError={scrapeError}
            >
                <SocialScraping />
            </Step>
            <Step
                title='Claim your username'
                subtitle='Claim your very own Hype handle'
                hasSubmitError={publisherUpdateError}
                outerClasses='section'
                innerClasses='py-12 px-6'
                showHypeLogo={true}
            >
                <UsernameCheck />
            </Step>
            <Step
                outerClasses="subscription-wall"
                childrenClasses='h-screen p-6'
                showHypeLogo={false}
                hide={showTrial === false || inApp === true}
            >
                <SubscriptionWall />
            </Step>
            <Step
                outerClasses="download-wall"
                childrenClasses='h-screen p-6' 
                showHypeLogo={false}
            >
                <DownloadWall />
            </Step>
        </Wizard>
    )
}

export default Signup
