/** @namespace FeatureManager.slice */

import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'

import fetchApps from './fetchApps'

const initialState = {
    apps: [],
    appTiers: [],
    fetchAppsInProgress: null,
    fetchAppsError: null,
}

const featureManagerSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        [fetchApps.pending]: (state) => {
            state.fetchAppsInProgress = true
            state.fetchAppsError = null
        },
        [fetchApps.fulfilled]: (state, { payload: apps }) => {
            Object.entries(initialState).forEach(([key, value]) => state[key] = value)
            state.apps = apps
            state.appTiers = apps.reduce((acc, app) => {
                return [
                    ...(acc || []),
                    ...(app?.tiers || []),
                ]
            }, [])

            state.fetchAppsInProgress = false
            state.fetchAppsError = null
        },
        [fetchApps.rejected]: (state, { payload: error }) => {
            Object.entries(initialState).forEach(([key, value]) => state[key] = value)
            state.fetchAppsInProgress = false
            state.fetchAppsError = error
        },
    }
})

// SELECTORS
const getFeatureManager = (state) => state[name]

const getApps = createDraftSafeSelector(
    getFeatureManager,
    (featureManager) => featureManager?.apps,
)

const getAppsTiers = createDraftSafeSelector(
    getFeatureManager,
    (featureManager) => featureManager?.appTiers,
)

const getFeatures = createDraftSafeSelector(
    getAppsTiers,
    (apps) => {
        const features = apps?.reduce((acc, app) => {
            app?.features?.forEach((feature = null) => {
                if (!feature?.id) {
                    return
                }

                // if the featue isn't already stored, store it
                if (!acc[feature?.id]) {
                    acc[feature?.id] = {
                        ...feature,
                        hasAccess: false,
                        appsProvidingAccess: [],
                        relatedApps: [],
                    }
                }

                // feature should have a has_access indicator
                // feature should indicate which apps give it access
                if (app?.has_access) {
                    acc[feature?.id].hasAccess = true
                    acc[feature?.id].appsProvidingAccess.push(app?.id)
                }

                // feature should indicate related apps
                acc[feature?.id].relatedApps.push(app)
            })
            return acc
        }, {}) || {}

        return Object.values(features)
    },
)

const getFetchAppsInProgress = createDraftSafeSelector(
    getFeatureManager,
    (featureManager) => featureManager?.fetchAppsInProgress,
)

// GENERAL
const takes = () => [
    ...fetchApps.takes,
]

const featureManagerInitialState = {
    [name]: initialState,
}

const featureManagerReducer = {
    [name]: featureManagerSlice.reducer,
}

// EXPORTS
export default featureManagerSlice.reducer

export {
    takes,
    featureManagerInitialState,
    featureManagerReducer,
    fetchApps,
    getFeatures,
    getApps,
    getAppsTiers,
    getFetchAppsInProgress,
}
