/** @namespace FeatureManager.dependencies */
import { getPublisherId, getBillingInfoStatuses } from '@/entities/Publisher'
import createAsyncSaga from '@/utils/store/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'

const name = 'featureManager'

export {
    createAsyncSaga,
    endpoints,
    name,
    getPublisherId,
    getBillingInfoStatuses,
}
