/** @namespace FeatureManager */

import {
    featureManagerInitialState,
    featureManagerReducer,
    takes,
    fetchApps,
} from './slice'

import useFeatureManager from './hooks/useFeatureManager'
import useHasActiveSubscription from './hooks/useHasActiveSubscription'

import * as constants from './constants'

export default {
    featureManagerInitialState,
    featureManagerReducer,
    takes,
}

export {
    constants,
    fetchApps,
    useFeatureManager,
    useHasActiveSubscription,
}
